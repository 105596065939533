<template>
    <sui-container id="main dashboard">
        <sui-menu id="main-menu">
            <sui-menu-item header link @click="goDashboardHome">
                <img src="../../src/assets/tsaro-logo-header.png" id="logo"/>
            </sui-menu-item>
            <sui-menu-menu position="right">
                <sui-menu-item link>
                    <sui-icon name="user circle outline"></sui-icon>
                    Hello {{userName}}  <span id="user-role-text"> [{{userRole}}]</span>
                </sui-menu-item>
                <sui-menu-item link @click="logout">
                    <sui-icon name="sign-out"></sui-icon>
                    Sign Out
                </sui-menu-item>
            </sui-menu-menu>
        </sui-menu>
        <sui-grid columns="two" centered id="sidebar">
            <sui-grid-row>
                <sui-grid-column :width="4" :mobile="16" :tablet="4" :computer="4">
                    <sui-menu vertical color="blue" inverted>
                        <router-link to="/dashboard/home" is="sui-menu-item" active-class="active">
                            <sui-icon name="home"></sui-icon>
                            Home
                        </router-link>
                    </sui-menu>
                    <sui-menu vertical color="blue" inverted>
                        <div :class="{hide:!showCamera}">
                            <router-link to="/dashboard/camera" is="sui-menu-item" active-class="active">
                                <sui-icon name="video"></sui-icon>
                                Camera Management
                            </router-link>
                        </div>
                        <div :class="{hide:!showLocation}">
                            <router-link to="/dashboard/location" is="sui-menu-item" active-class="active">
                                <sui-icon name="building"></sui-icon>
                                Location Management
                            </router-link>
                        </div>
                        <div :class="{hide:!showAccess}">
                            <router-link to="/dashboard/access" is="sui-menu-item" active-class="active">
                                <sui-icon name="key"></sui-icon>
                                Access Management
                            </router-link>
                        </div>
                        <div :class="{hide:!showContact}">
                            <router-link to="/dashboard/contact" is="sui-menu-item" active-class="active">
                                <sui-icon name="address book outline"></sui-icon>
                                Contact Management
                            </router-link>
                        </div>
                    </sui-menu>
                    <sui-divider clearing hidden></sui-divider>
                </sui-grid-column>
                <sui-grid-column :width="12" :mobile="16" :tablet="12" :computer="12">
                    <router-view/>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Dashboard",
        methods: {
            goDashboardHome: function () {
                this.$router.push('/dashboard/home');
            },
            logout: async function () {
                try {
                    await axios({
                        "method": "POST",
                        "url": "/api/user/logout",
                        "headers": {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        "data": {
                            "token": this.$cookies.get('token'),
                            "tokenId": this.$cookies.get('tokenId'),
                        }
                    });
                } finally {
                    await this.$router.push('/');
                }
            },
            getRole: function () {
                axios({
                    method: "POST",
                    url: "/api/user/metadata",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),
                    }
                }).then((res) => {

                    const r = res.data.role;
                    console.log(res.data);
                    switch (r) {
                        case "AS":
                            this.showCamera = true;
                            this.showLocation = true;
                            this.showAccess = true;
                            this.showContact = true;
                            break;
                        case "PM":
                            this.showCamera = true;
                            this.showLocation = true;
                            this.showAccess = true;
                            this.showContact = true;
                            break;
                        case "BM":
                            this.showCamera = true;
                            this.showLocation = true;
                            this.showAccess = false;
                            this.showContact = true;
                            break;
                        case "SG":
                            this.showCamera = true;
                            this.showLocation = false;
                            this.showAccess = false;
                            this.showContact = true;
                            break;
                        case "SI":
                            this.showCamera = true;
                            this.showLocation = false;
                            this.showAccess = false;
                            this.showContact = false;
                            break;
                    }
                    this.$config._userRole = r;
                    this.$config._userId = res.data.id;
                    for (let i of this.$config.waitingBuffers) {
                        i();
                    }
                    this.userRole = r;
                    this.userName = res.data.firstName + " " + res.data.lastName
                }).catch(() => {
                    this.$router.push("/")
                })
            }
        },
        data: function () {
            return {
                showCamera: false,
                showLocation: false,
                showAccess: false,
                showContact: false,
                userRole: "",
                userName: "",
            }
        },
        created: function () {
            this.getRole()
        }


    }
</script>

<style scoped>
    #logo {
        width: 3.5em;
    }

    #main-menu {
        margin-top: 20px;
    }

    #sidebar .menu {
        width: 100%;
    }

    .ui.menu .hide{
        display: none;
    }

    .ui.inverted.blue.menu, .ui.inverted.menu .blue.active.item {
        background-color: #3a98fe;
    }

    #user-role-text {
        color: lightgray;
        margin-left: 5px;
        font-size: 80%;
    }

</style>
